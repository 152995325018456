@mixin background-tint($tint_color, $image_url) {
  background-image:
  linear-gradient($tint_color, $tint_color),
  url($image_url);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.package-item-faq {
  .package-info {
    .package-img > img {
      cursor: pointer;
    }
    .faq-content {
      max-height: 0;
      overflow: hidden;
      transform: translateY(-5%);
      opacity: 0;
      transition: transform 0.5s, opacity 0.25s, max-height 0.5s;

      &.open {
        opacity: 1;
        max-height: 100%;
        transform: translateY(0);
      }
    }
  }
}

/*
LINEUP PAGE
*/
.lineup {
  main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  h2 {
    font-family: blackoutmidnight, Helvetica, sans-serif;
  }
  #container {
    max-width: 1440px;
  }
  .lineup-banner {
    margin: 0 10px;

    .banner-image {
      background: url("//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/hb-lineup-banner.png") no-repeat center center;
      background-size: contain;
      width: 90%;
      max-width: 1260px;
      height: 50vh;
      margin: 0 auto 1em;
    }
  }
  .artist {
    background-size: cover;
    min-height: 300px;
    flex-basis: 30%;
    display: flex;
    align-content: flex-start;
    justify-content: flex-end;
    margin: 1%;

    h2 {
      color: #fff;
      font-size: 1.3em;
      text-align: right;
    }
  }
  .jason-mraz {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/JM.jpeg")
  }
  .fitz-and-the-tantrums {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/fitz.jpeg")
  }
  .john-densmore {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/JD.jpeg")
  }
  .morgxn {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/morgxn.jpeg")
  }
  .phillip-phillips {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/PP.jpg")
  }
  .robby-krieger {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/RK.jpg")
  }
  .dave-stewart {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/Dave-Stewart.jpg")
  }
  .ingrid-michaelson {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/Ingrid-Michaelson.jpg")
  }
  .kaya-stewart {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/Kaya-Stewart.jpeg")
  }
  .maddie-poppe {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/Madde-Poppe.jpg")
  }
  .scarypoolparty {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/ScaryPoolParty.jpg")
  }
  .voices-of-our-city-choir {
    @include background-tint(rgba(0, 191, 243, 0.4), "//static.wonderfulunion.net/groundctrl/clients/homeward-bound/lineup-page/Voices-Of-Our-City-Choir.jpg")
  }
  .fancy-wrap {
    background-color: #394551;
  }
  .modal {
    padding: 10px;
    background-color: #394551;
    display: flex;
    flex-wrap: wrap;

    .left, .right {
      width: 48%;
      margin: 1%;
    }
    .right p:first-of-type {
      margin-top: 0;
    }
    .artist-name {
      display: inline-block;
      font-size: 2em;
      width: 60%;
      padding: 0;
      margin-top: 1em;
    }
    .social-links {
      width: 38%;
      display: inline-block;
      margin-top: 1em;
      text-align: right;

      > li {
        display: inline-block;

        a {
          font-size: 1.5em;
          padding: 0 0.2em;
        }
      }
    }
  }

  //Fancybox Overrides
  #fancybox-wrap {
    width: 96% !important;
    left: 2% !important;
    margin: 0 auto;
    padding: 0;
  }
  #fancybox-content p, #fancybox-content a, #fancybox-content b {
    color: #fff;
  }
  #fancybox-overlay {
    position: fixed;
  }
  #fancybox-content {
    padding: 0;
    width: 100% !important;
    max-width: 1140px;

    .artist-name {
      color: #00bff3;
    }
    .social-links > li {
      a:hover {
        color: #00bff3;
      }
    }
    p {
      font-weight: 400;
    }
  }
  #fancybox-outer {
    max-width: 1140px;
    margin: 0 auto;
  }

  @media (max-width: 1050px) {
    .lineup-banner {
      .banner-image {
        height: 40vh;
      }
    }
    .artist {
      flex-basis: 45%;
    }
    .modal {
      .social-links {
        li > a {
          font-size: 1.3em;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .modal {
      .left, .right {
        width: 98%;
      }
      .artist-name {
        text-align: left;
      }
    }
  }

  @media (max-width: 500px) {
    .lineup-banner {
      .banner-image {
        height: 25vh;
      }
    }
    .artist {
      flex-basis: 100%;
    }
    .modal {
      .artist-name {
        width: 100%;
        text-align: center;
      }
      .social-links {
        width: 100%;
        text-align: center;
      }
    }
  }
}

/*
FOOTER
*/
.site-footer {
  .social-nav {
    .social-item > a {
      font-size: 1.5em;
    }
  }
}